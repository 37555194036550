<template>
  <div class="flex flex-col mb-10">

    <div style="margin-left: -10px; padding:30px; border: 1px solid #8080807a; border-radius: 25px; border-radius: 5px;">
   
        <Content textColor="black" labelClass="text-gray-900" label="Package of The Course" class="my-3 text-uppercase w-full"  :text="data.name" /> 
        <Content textColor="black" labelClass="text-gray-900"  label="Package Description" class="my-3 text-uppercase w-full"  :html="data.description" /> 
        <!-- <Content textColor="black" labelClass="text-gray-900"  label="Course Type" class="my-3 text-uppercase w-full"  :text="data.type" />  -->
        <Content textColor="black" labelClass="text-gray-900"  label="Package Link" class="my-3 text-uppercase w-full"  :text="data.online_link" /> 
        <Content textColor="black" labelClass="text-gray-900"  label="Package Properties" class="my-3 text-uppercase w-full" /> 
        <ul>
            <li v-if="data.package_detail.is_pte_practice" class="font-bold">Number of PTE Practice <span style="margin-left:90px">{{data.package_detail.pte_practice}}</span></li> 
            <li v-if="data.package_detail.is_practice_test" class="font-bold">Number of Practice Test <span style="margin-left:89px">{{data.package_detail.practice_test}}</span></li> 
            <li v-if="data.package_detail.is_mock_test" class="font-bold">Number of Mock Test <span style="margin-left:105px">{{data.package_detail.mock_test}}</span></li> 
            <li v-if="data.package_detail.is_quiz"  class="font-bold">Number of Quiz <span style="margin-left:140px">{{data.package_detail.quiz_test}}</span></li> 
            <li v-if="data.package_detail.is_materials" class="font-bold">Materials<span style="margin-left:181px">{{data.package_detail.material_test}}</span></li> 
            <li v-if="data.package_detail.is_live_class" class="font-bold">Live Class<span style="margin-left:179px">{{data.package_detail.live_classes}}</span></li> 
            <li v-if="data.package_detail.is_webinars" class="font-bold">Webinars <span style="margin-left:178px">{{data.package_detail.webinars}}</span></li> 
            <li v-if="data.package_detail.is_one_to_one_appointment" class="font-bold">One To One Appointment <span style="margin-left:82px">{{data.package_detail.OneToOneAppointment}}</span></li> 
        </ul>
        <div class="md-layout">
            <div class="md-layout-item md-size-25">
                <Content textColor="black" labelClass="text-gray-900"  label="Show Double Price" class="my-3 text-uppercase w-full"  :text="data.package_detail.is_show_double_price==1?'Yes':'Not'" /> 
            </div>
            <div class="md-layout-item md-size-25">
                <Content textColor="black" labelClass="text-gray-900"  label="Feature Status" class="my-3 text-uppercase w-full"  :text="data.package_detail.is_featured==1?'Featured':'Not Featured'" /> 
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-size-25">
                <Content textColor="black" labelClass="text-gray-900"  label="Weekly New Price" class="my-3 text-uppercase w-full"  :text="'$'+data.package_detail.weekly_new_price" /> 
            </div>
            <div class="md-layout-item md-size-25">
                <Content textColor="black" labelClass="text-gray-900"  label="Weekly Old Price" class="my-3 text-uppercase w-full"  :text="'$'+data.package_detail.weekly_old_price" /> 
            </div>
        </div>
        <div class="md-layout">
            <div class="md-layout-item md-size-25">
                <Content textColor="black" labelClass="text-gray-900"  label="Monthtly New Price" class="my-3 text-uppercase w-full"  :text="'$'+data.package_detail.monthly_new_price" /> 
            </div>
            <div class="md-layout-item md-size-25">
                <Content textColor="black" labelClass="text-gray-900"  label="Monthtly Old Price" class="my-3 text-uppercase w-full"  :text="'$'+data.package_detail.monthly_old_price" /> 
            </div>
        </div>
        <div class="flex justify-end mt-4">
      
        <Button
            :loading="loading"
            class="bg-victoria rounded text-white mr-0"
            label="Next"
            type="button"
            @click="submit"
        >
        </Button>
        </div>
    </div>   
  </div>
</template>

<script>
import {
  Button,
} from "@/components";
import Content from "@/components/atom/Content";
export default {
  components: {
    Button,
    Content 
  },

  data() {
    return {
      loading: false,
      data:''
    }
  },
  computed:{
  
   },
  
   methods:{
   
    submit() {
      
      this.$emit("menu", this.$route.name);
      this.$router.push({ name: 'packageCreation.preview' });
    },


    clearAll() {
      this.$emit("clear-all", "");
    }
   },

  created() {

    let existData = this.getLocal('package-create-info');
    if (existData) this.data = existData;
    
  }
}
</script>